<template>
  <el-container v-loading="loading" style="position: relative;">
    <div style="padding:0;position: absolute;top:-18px;">
    当前位置：
    <span v-for="(item,index) in getLocation()" :key="index">
    <span v-if="index<getLocation().length-1"  @click="openDir(item.id)" style="color:#3888F7;cursor: pointer;">{{item.name}} > </span>
    <span v-else>{{item.name?item.name:'我的网盘'}}</span>
    </span>
    <!-- <span v-for="(item,index) in getLocation()" :key="index" @click="openDir(item.id)" v-else>{{item.name}}-</span> -->
    </div>
    
    <el-table :data="datas" :header-cell-style="{background:'#F5F5F5',color:'#000'}" style="margin-top: 16px;">
      <el-table-column prop="name" label="文件名" width="250">
        <template slot-scope="scope">
          <span v-if="scope.row.source!=2" style="display: flex;align-items: center;">
           <img :src="scope.row.coverUrl?scope.row.coverUrl:'https://admin.wuhousmartedu.com:9000/prod/upload/20220802/f5b472c6a02fb981d72b2c24b05582e6.png'" style="min-width: 40px;width: 40px;height:40px;border:1px #ccc solid;border-radius:5px"/> <a @click="toDetails(scope.row.id)" style="padding-left:20px;color: blue;text-decoration: underline;">{{scope.row.name}}.{{scope.row.types}}</a>
          </span>
          <span v-else-if="scope.row.contentsId!='-1'" style="display: flex;align-items: center;" @click="openDir(scope.row.contentsId)">
            <div>
              <img src="../../../../assets/image/Group 676@2x.png" width="30px" style="padding: 0;margin-right: 5px;"/>
            </div>
            <div style="margin-top: -5px;">
              {{scope.row.contentName}}
            </div>
          </span>
        </template>
      </el-table-column>
     
      <el-table-column prop="size" label="大小" width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.source!=2"
            v-text="scope.row.size<1024?scope.row.size+'kb':(scope.row.size/1024).toFixed(2)+'mb'"></span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="type" label="类型" width="70">
        <template slot-scope="scope">
          <span v-if="scope.row.source!=2"
            v-text="scope.row.type==1?'文档':(scope.row.type==2?'音频':(scope.row.type==3?'视频':'图片'))"></span>
          <span v-else>文件夹</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="updateTime" label="修改日期" width="100">
        <template slot-scope="scope">
          <span v-text="scope.row.updateTime.split(' ')[0]"></span>
        </template>
      </el-table-column>
      <el-table-column prop="curLocationName" label="位置" fixed="right">
        <template slot-scope="scope">
          <span v-if="scope.row.source!=2">
            <el-tooltip content="点击修改位置" placement="top" effect="light">
              <el-popover placement="right" trigger="click">
                <el-tree :data="data" ref="tree" :props="defaultProps" :highlight-current="true" node-key="id"
                  @current-change="handleCheckChange"></el-tree>
                <div style="text-align: center;padding-top: 5px;">
                  <el-button size="small" style="width: 120px;background-color: #12C294;color: #fff;" @click="changeLocation(scope.row.id)">移 动
                  </el-button>
                </div>
                <el-button @click="currSelectLocal='',getContentsTree()" slot="reference" type="text">{{scope.row.curLocationName}}
                </el-button>

              </el-popover>
            </el-tooltip>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="putStatus" label="推送状态" fixed="right">
        <template slot-scope="scope">
          <span v-if="scope.row.source!=2" style="color: #7ccdfb;">
            <span type="text" v-if="scope.row.putStatus==''">待推送</span>
            <span type="text" v-if="scope.row.putStatus=='0'">推送中</span>
            <span type="text" v-if="scope.row.putStatus=='1'" style="color: #b0aeac;">推送完成</span>
            <span type="text" v-if="scope.row.putStatus=='2'" style="color: #FA7462;">推送失败</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column  label="操作" fixed="right" width="160">
        <template slot-scope="scope">
          <span v-if="scope.row.source!=2" style="color: #3888F7;">
            <el-button type="text" @click="pushSchool(scope.row.id,scope.$index,datas)">推送</el-button>
            <el-button type="text" @click="editResource(scope.row.id)">编辑</el-button>
           <el-button type="text"  @click="delRescource(scope.row.source==2?scope.row.contentsId:scope.row.id,scope.row.source,scope.$index,datas)" style="color: #FA7462;">删除</el-button>
          </span>
          <span v-else-if="scope.row.contentsId!='-1'">
            <el-button type="text"  @click="delRescource(scope.row.source==2?scope.row.contentsId:scope.row.id,scope.row.source,scope.$index,datas)" style="color: #FA7462;">删除</el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="资源编辑" :visible.sync="dialogRescource" width="900px" :destroy-on-close="true">
      <editResource ref="editResource" @upDateList="upDateList" @getDiskMax="getDiskMax"  @closeDialog='closeDialog'></editResource>
    </el-dialog>
  </el-container>
</template>

<script>
  import editResource from "@/views/resourceCenter/components/center/edit-resource";
  import {
    getPage_api,
    removeById_api,
    putToSchool_api,
    deleteContents_api,
    getContentsTree_api,
    updateSrc_api,
    getDetailById_api
  } from "@/api/resourceCenter/school/center";
  export default {
    components:{
      editResource
    },
    data() {
      return {
        dialogRescource:false,
        restaurants: [],
        datas: [],
        loading: false,
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        currSelectLocal: '', //选中的位置
        data: [], //资源树
        parms: {

        },
        emptyAddress:[],//空文件夹时位置存储
        currCatalog:'',//当前目录
      }
    },
    mounted() {
     this.getContentsTree();
    },
    methods: {
      getDiskMax(){
        this.$emit("getDiskMax");
      },
      closeDialog(){
        this.dialogRescource=false;
      },
      getContentsTree(){
        getContentsTree_api().then(r => {
          this.data = r.data;
        });
      },
      //获取列表
      async getList(parms) {
        
        // this.currCatalog={'label':'我的网盘','id':"1"};
        if (parms) {
          if(parms.type){
            this.parms.type=parms.type=="0"?'':parms.type;
          }else{
            this.parms = parms;
          }
        }
        this.loading = true;

        const {
          data
        } = await getPage_api(this.parms);
 /* 处理空文件特殊文件夹*/
        let arr=[];
        data.map(val=>{
          if(val.contentsId=='-1'){
            this.emptyAddress=[val]
          }else{
            arr.push(val);
          }
        })
        this.datas = arr;
        this.loading = false;
      },
      //删除资源
      delRescource(id, source,index, rows) { 
        let newArr=[{
          'contentsId':-1,
          'curLocationName':rows[index].curLocationName,
          'curLocationIds':rows[index].curLocationIds,
        }]
        this.emptyAddress=newArr;
        let str = '是否删除资源?';
        if (source == 2) {
          str = '此操作将会删除整个文件夹下的资源，是否继续？';
        }
        this.$confirm(str, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let res = '';
          if (source == 2) {
            res = await deleteContents_api({
              id: id
            });
          } else {
            res = await removeById_api({
              id: id
            });
          }

          if (res.success) {
             rows.splice(index, 1);
            this.$message.success("删除成功！");
            this.$emit("getDiskMax");
          }
        }).catch(()=>{
          
        });
      },
      //推送到学校
      pushSchool(id,index,row) {
        this.$confirm('是否将资源推送到学校?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const {
            success,
            msg
          } = await putToSchool_api({
            id: id
          });
          if (success) {
            this.$message.success("推送成功！");
            row[index].putStatus="0";
          } else {
            this.$message.warning(msg);
          }
        }).catch(()=>{
          
        })
      },
      //资源详情
      toDetails(id) {
        let routeData = this.$router.resolve({
          path: '/resource/center/details',
          query: {
            id: id,
            scene: "noSearch"
          }
        })
        window.open(routeData.href, '_blank');
      },
      //打开目录
      async openDir(id){
        this.$emit('setCatalogueId',id);
         this.loading=true;
         this.parms.contentId=id;
        const {
          data
        } = await getPage_api({contentId:id});
        this.loading=false;
        /* 处理空文件特殊文件夹*/
        let arr=[];
        data.map(val=>{
          if(val.contentsId=='-1'){
            this.emptyAddress=[val]
          }else{
            arr.push(val);
          }
        })
        this.datas=arr;
        // if(data.length>1){
        //   this.datas=data;
        // }else{
        //   this.emptyAddress=data;
        //   this.datas=[];
        // }
        
      },
     
      //选择资源位置
      handleCheckChange(res) {
        this.currSelectLocal = res;
      },
      //保存资源位置
      changeLocation(id) {
        if (this.currSelectLocal) {
          this.$confirm('是否将资源移至' + this.currSelectLocal.label + '目录?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            const {
              success,
              msg
            } = await updateSrc_api({
              contentId: this.currSelectLocal.id,
              resourceId: id
            });
            if (success) {
              this.$message.success("目录修改成功！");
              this.$emit("updateList",{"contentId":this.currSelectLocal.id})
              // this.getList();
            } else {
              this.$message.warning(msg);
            }
          }).catch(()=>{
          
        })
          return false;
        }
        this.$message.warning("请选择要更改的目录");
      },
      //修改资源
     async editResource(id){
        const {data}=await getDetailById_api({id});
        this.dialogRescource=true;
        this.$nextTick(()=>{
          this.$refs['editResource'].setDatas(data);
        })
      },
      //修改资源完毕
      upDateList(){
        this.dialogRescource=false;
        this.getList();
        //this.openDir(this.currCatalog.slice(-1)[0].id,this.currCatalog.slice(-1)[0].label);
      },
      //文件位置处理
      getLocation(){
        let route=[];
        if(this.datas.length>0){
          let location=this.datas[0];
          let ids=this.datas[0].curLocationIds.split(",");
          location.curLocationName.split('/').map((val,key)=>{
            route.push({
              name:val,
              id:ids[key]=='1'?'':(ids[key]?ids[key]:location.contentsId)
            })
          })
        }else{
          if(this.emptyAddress.length>0){
            let location=this.emptyAddress[0];
            let ids=this.emptyAddress[0].curLocationIds.split(",");
            location.curLocationName.split('/').map((val,key)=>{
              route.push({
                name:val,
                id:ids[key]
              })
            })
          }
        }
        return route;
      }
    }
  }
</script>

<style scoped>
  .crumbs{
    margin-right: 5px;
    cursor: pointer;
    color: #409eff;
    text-decoration: underline;
  }
</style>
