<template>
  <div class="warp">
    <div class="crumbs">
      <span style="color: #bababa">个人中心 ></span>
      {{ resourceCategory == 2 ? '我的收纳柜' : '我的收藏' }}
      <div
        style="display: inline-block"
        v-show="showWp"
        v-if="resourceCategory == 2">
        <div style="display: inline-block; margin-left: 30px; width: 160px">
          <el-progress
            :percentage="diskInfo.percentum"
            :format="format"
            :stroke-width="10"
            color="#12C294"></el-progress>
        </div>
        <div style="display: inline-block">
          <span style="color: #12c294">{{ diskInfo.currentUse }}</span>
          <span style="color: rgb(186, 186, 186)">
            /{{ diskInfo.diskUseMax }}G
          </span>
        </div>
      </div>
    </div>
    <div class="flex_row container">
      <div class="left">
        <div class="flex_row header">
          <div class="tabbar">
            <el-radio-group
              v-model="resourceCategory"
              fill="#fff"
              text-color="#000"
              @change="selectResource()">
              <el-radio-button
                label="2"
                v-show="showWp">
                我的收纳柜
              </el-radio-button>
              <el-radio-button label="3">我的收藏</el-radio-button>
            </el-radio-group>
          </div>
          <div class="types">
            <el-radio-group
              v-model="resourceType"
              style="width: auto"
              @change="search">
              <el-radio :label="0">全部</el-radio>
              <el-radio :label="1">文档</el-radio>
              <el-radio :label="2">音频</el-radio>
              <el-radio :label="3">视频</el-radio>
              <el-radio :label="4">图片</el-radio>
            </el-radio-group>
          </div>
          <div
            class="wp-btn"
            v-show="showWp"
            v-if="resourceCategory == 2">
            <el-button
              style="border: 1px #12c294 solid; color: #12c294"
              @click="dialogRescourceLocation = true">
              编辑网盘目录
            </el-button>
            <el-button
              style="background: #12c294; color: #fff"
              @click="uploadResource">
              个人资源上传
            </el-button>
          </div>
          <div
            v-else
            style="width: 270px; padding: 30px"></div>
        </div>
        <div class="content">
          <!-- <div class="crumbs" v-show="showWp">当前位置：我的网盘</div> -->
          <div class="table">
            <collectArk
              @updateList="selectResource"
              @setCatalogueId="setCatalogueId"
              @getDiskMax="getDiskMax"
              v-if="resourceCategory == 2"
              ref="collectArk"></collectArk>
            <myCollect
              v-if="resourceCategory == 3"
              ref="myCollect"></myCollect>
          </div>
        </div>
      </div>
      <div
        class="right"
        v-loading="loadingRecommend">
        <div class="title">
          资源智能推荐
          <div
            class="refresh"
            @click="refreshRecommend">
            <i class="el-icon-refresh-left"></i>
            &nbsp;换一批
          </div>
        </div>
        <el-table
          :data="tableData"
          height="481"
          :show-header="false"
          style="width: 100%"
          @row-click="toDetails">
          <el-table-column prop="name">
            <template slot-scope="scope">
              <span>
                <img
                  :src="scope.row.icon"
                  class="icon" />
              </span>
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      title="个人资源上传"
      :visible.sync="dialogRescource"
      width="850px"
      :destroy-on-close="true"
      v-if="dialogRescource">
      <uploadResource
        @updateList="selectResource"
        @getDiskMax="getDiskMax"
        @closeDialog="closeDialog"
        :keyid="catalogueId"></uploadResource>
    </el-dialog>
    <el-dialog
      title="存放位置管理"
      :visible.sync="dialogRescourceLocation"
      width="400px"
      :destroy-on-close="true"
      v-if="dialogRescourceLocation">
      <resourceLocation
        @updateList="selectResource"
        @getDiskMax="getDiskMax"></resourceLocation>
    </el-dialog>
  </div>

  <!-- <el-container style="width: 1200px;margin: 0 auto;margin-bottom: 20px;">
    <el-header class="m_t_15 search-area">
      <el-row type="flex">
        <el-col :span="12">
          <div class="search grid-content">
            <el-input placeholder="资源检索" v-model="searchValue" class="input-with-select">
              <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
            </el-input>
          </div>
        </el-col>
      </el-row>
      <el-row class="m_t_15">
        <el-radio-group v-model="resourceType" style="width: auto;">
          <el-radio :label="0">全部</el-radio>
          <el-radio :label="1">文档</el-radio>
          <el-radio :label="2">音频</el-radio>
          <el-radio :label="3">视频</el-radio>
          <el-radio :label="4">图片</el-radio>
        </el-radio-group>
      </el-row>
      <el-row v-if="resourceCategory=='2'">
        <el-button type="primary" style="margin-top: 12px;" @click="uploadResource">个人资源上传</el-button>
      </el-row>
    </el-header>
    <el-container style="background-color: #fff;" v-loading="loadingAll">
      <el-aside width="200px" class="resource-category">
        <div class="box">
          <div class="title">我的资源</div>
          <el-radio-group v-model="resourceCategory" fill="#b3d9fe" text-color="#000" @change="selectResource()"
            style="height: 482px;border: 1px #ebeef5 solid;">
            <el-radio-button label="2" v-show="showWp">我的收纳柜</el-radio-button>
            <el-radio-button label="3">我的收藏</el-radio-button>
          </el-radio-group>
        </div>
      </el-aside>
      <el-main>
        <collectArk v-show='resourceCategory==2' ref="collectArk"></collectArk>
        <myCollect v-show='resourceCategory==3' ref="myCollect"></myCollect>
        <prepareLessons v-show='resourceCategory==1'></prepareLessons>
      </el-main>
      <el-aside width="350px" class="resource-category" v-loading="loadingRecommend">
        <div class="box">
          <div class="title">
            资源智能推荐
            <div class="refresh" @click="refreshRecommend"><i class="el-icon-refresh-left"></i>&nbsp;换一批</div>
          </div>
          <el-table :data="tableData" height="481" border :show-header='false' style="width: 100%"
            @row-click="toDetails">
            <el-table-column prop="name">
              <template slot-scope="scope">
                <span><img :src="scope.row.icon" class="icon" /></span><span>{{scope.row.name}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-aside>
    </el-container>

  </el-container> -->
</template>

<script>
import collectArk from '@/views/resourceCenter/components/center/collect-ark';
import myCollect from '@/views/resourceCenter/components/center/my-collect';
// import prepareLessons from "@/views/resourceCenter/components/center/prepare-lessons";
import uploadResource from '@/views/resourceCenter/components/center/upload-resource';
import resourceLocation from '@/views/resourceCenter/components/center/resource-location';
import { regroup } from '@/utils/addIcon';
import {
  getDataByIntelligent_api,
  getDiskUseForMax_api,
} from '@/api/resourceCenter/school/center';
import { sessionGet } from 'utils/local';
export default {
  components: {
    collectArk,
    myCollect,
    resourceLocation,
    // prepareLessons,
    uploadResource,
  },
  data() {
    return {
      diskInfo: '',
      showWp: true,
      dialogRescource: false,
      dialogRescourceLocation: false,
      loadingAll: false,
      loading: false,
      loadingRecommend: false,
      resourceType: 0, //搜索框下的类型
      resourceCategory: '2',
      myResourceType: '', //我的资源下的类型
      searchValue: '',
      tableData: [],
      datas: '',
      parms: {
        current: 1,
        size: 10,
      }, //获取参数
      catalogueId: 1, //当前目录id
    };
  },
  created() {
    //三方登录
    // if(this.$route.query.ticket){
    //   setToken(this.$route.query.ticket).then(res=>{
    //     if(res){
    //       this.init();
    //     }
    //   })
    // }else{
    //   this.init();
    // }
  },
  mounted() {
    this.init();
  },
  computed: {
    userInfo() {
      let user = sessionGet('userInfo');
      if (user) {
        return user;
      } else {
        return {};
      }
    },
  },
  methods: {
    format() {},
    async init() {
      this.getDiskMax();
      this.loadingAll = true;
      //判断用户角色
      if (this.userInfo.role_name == 'student') {
        this.resourceCategory = '3';
        this.showWp = false;
      }
      //获取智能推荐
      const { data } = await getDataByIntelligent_api();
      this.tableData = regroup(data);
      //获取收纳柜
      this.selectResource();
      this.loadingAll = false;
    },
    async refreshRecommend() {
      this.loadingRecommend = true;
      //获取智能推荐
      const { data } = await getDataByIntelligent_api();
      this.tableData = regroup(data);
      this.loadingRecommend = false;
    },
    async selectResource(data) {
      this.myResourceType = '';
      switch (this.resourceCategory) {
        case '1':
          break;
        case '2':
          this.$refs.collectArk.getList(data);
          break;
        case '3':
          this.$refs.myCollect.getList(data);
          break;
      }
    },
    //查询
    search() {
      let data = {
        // name: this.searchValue,
        type: this.resourceType != 0 ? this.resourceType.toString() : '',
      };
      this.selectResource(data);
      //console.log(data);
    },
    //进入详情
    toDetails(row) {
      let routeData = this.$router.resolve({
        path: '/resource/details',
        query: {
          id: row.id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    //个人资源上传
    uploadResource() {
      this.dialogRescource = true;
    },
    closeDialog() {
      this.dialogRescource = false;
    },
    //刷新列表
    updateList() {
      this.selectResource();
    },
    //获取网盘容量
    async getDiskMax() {
      const { data } = await getDiskUseForMax_api();
      let use =
        data.currentUse < 1024
          ? data.currentUse + 'KB'
          : data.currentUse < 1024 * 1024
          ? (data.currentUse / 1024).toFixed(1) + 'MB'
          : (data.currentUse / 1024 / 1024).toFixed(1) + 'G';
      let percentum = (data.currentUse / (data.diskUseMax * 1024 * 1024)) * 100;
      let datas = {
        diskUseMax: data.diskUseMax,
        currentUse: use,
        percentum: parseInt(percentum),
      };
      this.diskInfo = datas;
    },
    closeDialogRescource() {
      this.dialogRescource = false;
    },
    //当前目录id
    setCatalogueId(id) {
      this.catalogueId = id ? id : 1;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .crumbs .el-progress-bar {
  padding-right: 10px;
}
::v-deep.el-radio-button:first-child .el-radio-button__inner {
  border: none;
}

::v-deep.el-dialog__wrapper {
  overflow: hidden;
}

::v-deep .el-dialog {
  border-radius: 10px;
}

::v-deep .el-radio-group {
  width: 100%;
}

::v-deep .el-radio-button {
  display: inline-block;
  margin-right: 15px;
}

::v-deep .el-radio-button.is-active {
  border-bottom: #12c294 3px solid;
}

::v-deep .el-radio-button__inner {
  width: 100%;
  text-align: left;
  color: #a4a4a4;
  padding: 10px 0;
  border: none;
}

::v-deep.is-active .el-radio-button__inner {
  font-weight: bold !important;
}

::v-deep .el-table__row {
  cursor: pointer;
}

::v-deep .el-table .cell {
  white-space: nowrap;
}

::v-deep.el-radio {
  margin-right: 20px;
}

::v-deep.el-radio__input.is-checked .el-radio__inner {
  background-color: #12c294;
  border-color: #12c294;
}

::v-deep.el-radio__input.is-checked + .el-radio__label {
  color: #12c294;
}

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: none;
}

.warp {
  width: 1300px;
  margin: 0 auto 40px auto;
  font-size: 14px;

  .crumbs {
    padding: 20px 0;
  }

  .container {
    justify-content: space-between;

    .left {
      width: 69%;
      border-radius: 10px;
      background-color: #fff;

      .header {
        justify-content: space-between;
        padding: 0 15px;

        .tabbar {
          padding-top: 5px;
        }

        .types {
          .el-radio-group {
            margin-top: 15px;
          }
        }

        .wp-btn {
          width: 270px;
          padding: 15px 0;

          .el-button {
            margin-top: 0px !important;
          }
        }
      }

      .content {
        padding: 0px 15px;

        .crumbs {
          padding: 5px 0px;
          margin-top: -22px;
          padding-bottom: 15px;
        }
      }
    }

    .right {
      width: 30%;
      background-color: #fff;
      border-radius: 10px;

      .title {
        font-size: 16px;
        font-weight: bold;
        padding: 15px;
        position: relative;

        .refresh {
          position: absolute;
          top: 18px;
          color: #9d9d9d;
          font-weight: normal;
          font-size: 12px;
        }
      }
    }
  }
}

.search-area {
  height: auto !important;
  padding: 15px 0 !important;
}

.resource-category {
}

.resource-category .title:before {
  content: '|';
  color: #0079f4;
  float: left;
  margin-left: 10px;
  margin-right: 5px;
  font-weight: bold;
}

.resource-category .title {
  background-color: #dfefff;
  height: 45px;
  line-height: 45px;
  font-size: 15px;
  position: relative;
}

.resource-category .lists .item {
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.lists .is-active {
  color: #0079f4;
  background-color: #b3d9fe;
}

.el-main {
  padding: 0 5px;
}

.icon {
  width: 20px;
  height: 20px;
  float: left;
  margin-left: 10px;
  margin-right: 10px;
}

.refresh {
  position: absolute;
  right: 10px;
  top: 0px;
  cursor: pointer;
}
</style>
