import word from "assets/image/word.png";
import pdf from "assets/image/pdf.png";
import ppt from "assets/image/ppt.png";
import excel from "assets/image/excel.png";
import video from "assets/image/video.png";
import voice from "assets/image/voice.png";
import jpg from "assets/image/pic.png";
import html from "assets/image/html.png";
export const icons= {
    doc: word,
    pdf: pdf,
    pptx: ppt,
    xlsx: excel,
    mp4: video,
    m3u8: video,
    mp3: voice,
    jpg: jpg,
    png: jpg,
    jpeg: jpg,
    svg: jpg,
    html: html,
}
export function regroup(datas) {
  let newObj=[]
  datas.forEach((val) => {
    val.icon=icons[val.types];
    newObj.push(val);
  })
  return newObj;
}
