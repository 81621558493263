<template>
  <div class="warp">
    <div style="color: #aeaeae;">移动位置</div>&nbsp;&nbsp;
      <div>
        <el-tree :data="tree" :render-content="renderContent" :highlight-current="true" :draggable="false" :default-expand-all="true"></el-tree>
      </div>
  </div>
</template>

<script>
  import more from "@/assets/image/more.png";
  import {
    getContentsTree_api,
    savePersonalDiskContents_api,
    // resourceSave_api,
    deleteContents_api,
    updatePersonalDiskContents_api,
  } from "@/api/resourceCenter/school/center"
  import url from "@/api/api";
  import {
    sessionGet
  } from "utils/local";
  export default {
    name: 'MaterUpload',

    data: () => ({
      tree:[],
      more,
      parentsIds:[]//父级目录id集合
    }),
    computed: {
      headers() {
        return {
          Authorization: 'token'
        };
      },
      baseUrl() {
        return url.baseUrl;
      },
      uploadType() {
        return this.uploadArguments.type;
      },
      uploadArgumentsObj() {
        return {
          ...this.uploadArguments,
          share: this.share
        };
      },
      userInfo() {
        let user = sessionGet('userInfo')
        if (user) {
          return user
        } else {
          return {}
        }
      }
    },

    created() {
      //获取树
      this.init();
    },
    methods: {
      renderContent(h, { node, data }) {
        if(node.level>1){
          return (
            <span class="custom-tree-node">
              <span>{node.label}</span>
              <span style="margin-left:10px">

              <el-popover
                placement="right"

                trigger="hover">
                <div class="toolMenu">
                  <div on-click={()=>this.updatePersonalDiskContents(node,data)}>编辑目录</div>
                  <div on-click={()=>this.savePersonalDiskContents(node,data)}>添加子目录</div>
                  <div on-click={()=>this.deleteContents(node,data)}>删除目录</div>
                </div>
               <img src={more} style="width:20px;" slot="reference"/>
              </el-popover>
              </span>
            </span>);
        }else{
          return (
            <span class="custom-tree-node">
              <span>{node.label}</span>
              <span style="margin-left:10px">

              <el-popover
                placement="right"

                trigger="hover">
                <div class="toolMenu">
                  <div on-click={()=>this.savePersonalDiskContents(node,data)}>添加子目录</div>
                </div>
               <img src={more} style="width:20px;" slot="reference"/>
              </el-popover>
              </span>
            </span>);
        }
            },
         getParentsId(item){
           this.parentsIds.push(item.data.id);
           if(item.level>1){
             this.getParentsId(item.parent)
           }
         } ,
       //修改文件目錄
       // moveTree(before,after){
       //   this.parentsIds=[];
       //   let selected=before.data;//选择的目录
       //   let destination=after.data;//要去的目录
       //   //移动操作
       //   //console.log(selected,destination)
       //   //遍历父级目录
       //   this.getParentsId(after)
       //   //console.log(this.parentsIds)
       // },



      init() {
        this.getContentsTree().then((data) => {
          this.tree=data;
        })
      },
      //获取网盘列表
      getContentsTree() {
        return new Promise((resolve) => {
          getContentsTree_api().then(r => {
            this.data = r.data;
            resolve(r.data);
          });
        })
      },
      //增加资源树
      async savePersonalDiskContents(node,data) {
       this.parentsIds=[];
      this.getParentsId(node);
       this.$prompt('请输入目录名', '新增目录', {
         confirmButtonText: '确定',
         cancelButtonText: '取消',
         closeOnClickModal: false,
         inputPattern: /^[\s\S]*.*[^\s][\s\S]*$/,
         inputErrorMessage: '请输入目录名'
       }).then(async ({
         value
       }) => {
         let datas = {
           "name": value,
           "parentId": data.id,
           "parentsId": this.parentsIds.reverse().toString(),
           "userId": this.userInfo.user_id,
           "sort": data.level + 1
         };
         const {
           success
         } = await savePersonalDiskContents_api(datas);
         if (success) {
           this.$message.success("添加成功");
           this.init();
           this.$emit('updateList',{contentId:data.id});
         }
       });

      },
      //修改目录
      async updatePersonalDiskContents(node,data) {
          this.$prompt('请输入目录名', '编辑目录', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            closeOnClickModal: false,
            inputValue: data.label,
            inputPattern: /^[\s\S]*.*[^\s][\s\S]*$/,
            inputErrorMessage: '请输入目录名'
          }).then(async ({
            value
          }) => {
            let datas = {
              "name": value,
              "id": data.id,
              "userId": this.userInfo.user_id
            };
            const {
              success
            } = await updatePersonalDiskContents_api(datas);
            if (success) {
              this.$message.success("修改成功");
               this.$emit('updateList',{contentId:node.parent.data.id});
              this.init();
            }
          });
      },
      //删除目录
      deleteContents(node,data) {
          this.$confirm('是否删除'+data.label+'目录?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            const {
              success
            } = await deleteContents_api({
              id: data.id
            });
            if (success) {
              this.$message.success("删除成功！");
              this.$emit('updateList',{contentId:node.parent.data.id});
              this.$emit("getDiskMax");
                const parent = node.parent;
                        const children = parent.data.children || parent.data;
                        const index = children.findIndex(d => d.id === data.id);
                        children.splice(index, 1);

            }

          }).catch(() => {
          return false;
        })
      },

      // 递归全部节点
      // findAllNode(data) {
      //   if (!this.saveNodeTmp.includes(data.id)) {
      //     this.saveNodeTmp.push(data.id)
      //   }
      //   if (data.children.length>0) {
      //     this.findAllNode(data.children[0])
      //   }
      // },


    }
  };
</script>
<style>
  .el-popover .toolMenu div{
      cursor: pointer;
    }
    .el-popover .toolMenu div:hover{
      color: #009dff;
    }
</style>
<style lang="scss" scoped>
  .warp {
    display: flex;
    flex-direction: row;
  }
 ::deep .custom-tree-node{
    display: flex;
    justify-content: space-around;
  }
</style>
