// 引入封装的第一层axios接口
import request from 'utils/req'

//个人中心智能推荐
export const getDataByIntelligent_api = (params) => {
    return request({
        url: '/api/intelligentData/getIntelligentData',
        method: 'get',
        params: {
            ...params
        }
    })
}
//我的收藏
export const myCollection_api = (params) => {
    return request({
        url: '/api/blade-student/student/myCollection',
        method: 'get',
        params: {
            ...params
        }
    })
}
// 取消收藏
export const addResourcesCollect_api = (params) => {
    return request({
        url: '/api/schoolIndex/addResourcesCollect',
        method: 'get',
        params: {
            ...params
        }
    })
}
//分享资源
export const getResourcesShare_api = (params) => {
    return request({
        url: '/api/blade-resources/data/getResourcesShare',
        method: 'get',
        params: {
            ...params
        }
    })
}
//验证资源提取码
export const getShare_api = (params) => {
    return request({
        url: '/api/blade-resources/data/getShare',
        method: 'get',
        params: {
            ...params
        }
    })
}
//获取个人资源目录树
export const getContentsTree_api = (params) => {
    return request({
        url: '/api/personalDisk/getContentsTree',
        method: 'get',
        params: {
            ...params
        }
    })
}
//增加资源树
export const savePersonalDiskContents_api = (params) => {
    return request({
        url: '/api/personalDisk/savePersonalDiskContents',
        method: 'post',
        data: {
            ...params
        },
       headers: {
           "Content-Type": "application/json;charset=UTF-8;"
       }
    })
}
//删除资源树
export const deleteContents_api = (params) => {
    return request({
        url: '/api/personalDisk/deleteContents',
        method: 'get',
        params: {
            ...params
        }
    })
}
//修改资源目录
export const updatePersonalDiskContents_api = (params) => {
    return request({
        url: '/api/personalDisk/updatePersonalDiskContents',
        method: 'post',
        data: {
            ...params
        },
       headers: {
           "Content-Type": "application/json;charset=UTF-8;"
       }
    })
}
//保存资源
export const resourceSave_api = (params) => {
    return request({
        url: '/api/personalDisk/resourceSave',
        method: 'post',
        data: {
            ...params
        },
       headers: {
           "Content-Type": "application/json;charset=UTF-8;"
       }
    })
}
//个人网盘分页
export const getPage_api = (params) => {
    return request({
        url: '/api/personalDisk/getPage',
        method: 'get',
        params: {
            ...params
        }
    })
}
//个人网盘删除文件
export const removeById_api = (params) => {
    return request({
        url: '/api/personalDisk/removeById',
        method: 'get',
        params: {
            ...params
        }
    })
}
//资源推送到学校
export const putToSchool_api = (params) => {
    return request({
        url: '/api/personalDisk/putToSchool',
        method: 'get',
        params: {
            ...params
        }
    })
}
//资源详情
export const getDetailById_api = (params) => {
    return request({
        url: '/api/personalDisk/getDetailById',
        method: 'get',
        params: {
            ...params
        }
    })
}
//修改个人网盘资源目录
export const updateSrc_api = (params) => {
    return request({
        url: '/api/personalDisk/updateSrc',
        method: 'get',
        params: {
            ...params
        }
    })
}
//批量保存个人网盘
export const resourceSaveBatch_api = (params) => {
    return request({
        url: '/api/personalDisk/resourceSaveBatch',
        method: 'post',
        data: params
        
    })
}
//查询标签集合
export const getLabelList_api = (params) => {
    return request({
        url: '/api/blade-resources/resourcesData/getLabelList',
        method: 'get',
        params: {
            ...params
        }
    })
}
//获取网盘容量
export const getDiskUseForMax_api = (params) => {
    return request({
        url: '/api/personalDisk/getDiskUseForMax',
        method: 'get',
        params: {
            ...params
        }
    })
}

export const putFile_api = (data) => {
    return request({
        url: '/api/blade-resource/oss/endpoint/put-file',
        method: 'post',
        headers: {
          "Content-Type": 'multipart/form-data',
        },
        data
    })
}

