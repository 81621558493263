<template>
  <div v-loading="loading">
    <el-table :data="datas.records" style="width: 100%" :header-cell-style="{background:'#F5F5F5',color:'#000'}">
      <el-table-column prop="name" label="文件名" width="330">
        <template slot-scope="scope">
          <div style="display: flex;align-items: center;">
          <span>
            <img :src="imgToRespomse(scope.row.coverUrl)" width="40px" height="40px"
                 style="border:1px #ccc solid;border-radius:5px;min-width: 40px;"/>
          </span>
            <span>
            <a @click="toDetails(scope.row)"
               style="color: #000;text-decoration: none;margin-left: 10px;">{{ scope.row.name }}.{{
                scope.row.types
              }}</a>
          </span>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="size" label="文件类型" width="80">
        <template slot-scope="scope">
          <span v-text="scope.row.size<1024?scope.row.size+'kb':(scope.row.size/1024).toFixed(2)+'mb'"></span>
        </template>
      </el-table-column> -->
      <el-table-column prop="type" label="文件类型" width="120">
        <template slot-scope="scope">
          <span v-text="scope.row.type==1?'文档':(scope.row.type==2?'音频':(scope.row.type==3?'视频':'图片'))"></span>
        </template>
      </el-table-column>

      <el-table-column prop="updateTime" label="收藏日期">
        <template slot-scope="scope">
          <span v-text="scope.row.collectTime.split(' ')[0]"></span>
        </template>
      </el-table-column>
      <el-table-column prop="source" label="操作">
        <template slot-scope="scope">
          <el-button @click="handleCancel(scope.row)" type="text" size="small" style="color: #7ccdfb;font-size:14px;">
            取消收藏
          </el-button>
          <el-button type="text" size="small" @click="handleShare(scope.row)" style="color: #12C294;font-size:14px;">
            分享
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" v-if="datas.total>0">
      <div style="font-size: 15px;margin-top: 3px;">当前第{{ datas.current }}页/共{{ datas.pages }}页，{{ datas.total }}条</div>
      <el-pagination @current-change="handleCurrentChange"
                     :current-page.sync="datas.current" :page-size="datas.size" layout=" prev, pager, next" background
                     :total="datas.total" hide-on-single-page>>
      </el-pagination>
    </div>
    <el-dialog title="分享文件：" :visible.sync="dialogVisible" width="30%">
      <el-form :model="form" label-width="120px" :rules="rules" ref="formTime" v-loading="createloading">
        <el-form-item label="有效期：" prop="time">
          <el-autocomplete class="inline-input" v-model="form.time" :fetch-suggestions="querySearch"
                           placeholder="请输入有效期(天)" @select="handleSelect" oninput="value=value.replace(/[^\d]/g,'')"
                           maxLength='9'
                           :clearable="true"></el-autocomplete>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button style="background: #12C294;color: #fff;" @click="createLink">创建链接</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="'分享文件:'+current.name" :visible.sync="dialogLink" width="30%">
      <el-form label-width="120px">
        <el-form-item label="链接：">
          <el-input v-model="link" :readonly="true" style="width: 250px;"></el-input>
        </el-form-item>
        <el-form-item label="提取码：">
          <el-input v-model="code" :readonly="true" style="width: 100px;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button style="background: #12C294;color: #fff;" @click="copyLink">复制链接及提取码</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>

</template>

<script>
import {
  addResourcesCollect_api,
  myCollection_api,
  getResourcesShare_api
} from "@/api/resourceCenter/school/center"
import {imgToRespomse} from '@/utils/imgToResponse'

export default {
  data() {
    return {
      restaurants: [],
      datas: '',
      loading: false,
      createloading: false,
      dialogLink: false,
      dialogVisible: false,
      link: '',
      code: '',
      form: {
        time: '永久有效'
      },
      current: '', //当前选中项
      rules: {
        time: [{
          required: true,
          message: '请输入分享有效期',
          trigger: 'blur'
        }]
      },
      parms: {
        current: 1,
        size: 10,
      }
    }
  },
  mounted() {
    this.restaurants = this.loadAll();
  },
  methods: {
    imgToRespomse(url) {
      return imgToRespomse(url);
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    //进入详情
    toDetails(row) {
      let routeData = this.$router.resolve({
        path: '/resource/details',
        query: {
          id: row.id
        }
      })
      window.open(routeData.href, '_blank');
    },
    loadAll() {
      return [{
        "text": "永久有效",
        "value": "永久有效"
      },
        {
          "text": "30",
          "value": "30天"
        },
        {
          "text": "7",
          "value": "7天"
        },
        {
          "text": "1",
          "value": "1天"
        },
      ];
    },
    //获取列表
    async getList(parms) {
      if (parms) {
        this.parms.name = parms.name;
        this.parms.type = parms.type;
      }
      this.loading = true;
      const {
        data
      } = await myCollection_api(this.parms);
      this.datas = data;
      this.loading = false;
    },
    //取消收藏
    handleCancel(r) {
      this.$confirm('是否取消收藏?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        addResourcesCollect_api({
          "id": r.id,
          "type": 0
        }).then((res) => {
          if (res.success) {
            this.$message.success('取消收藏成功');
            this.getList();
          }
        })

      })

    },
    //创建链接
    createLink() {
      this.$refs['formTime'].validate((valide) => {
        if (valide) {
          this.createloading = true;
          let time = this.form.time == "永久有效" ? 0 : this.form.time;
          getResourcesShare_api({
            "resourcesId": this.current.id,
            "time": time
          }).then((r) => {
            if (r.success) {
              this.link = window.location.href.split("#")[0] + '#/resource/share?id=' + this.current.id +
                  "&scene=noSearch";
              this.code = r.data;
              this.dialogVisible = false;
              this.dialogLink = true;
            }
            this.createloading = false;
          }, () => {
            this.$message.error("创建链接失败");
            this.createloading = false;
          })
        }
      })
    },
    //分享资源
    handleShare(r) {
      this.form.time = "永久有效";
      this.current = r;
      this.dialogVisible = true;
    },
    handleSelect(item) {
      this.form.time = item.text
    },
    handleCurrentChange(current) {
      this.parms.current = current;
      this.getList();
    },
    //复制提取码和链接
    copyLink() {
      let oInput = document.createElement("input");
      oInput.value = "链接：" + this.link + " 提取码：" + this.code;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.remove();
      this.$message.success("复制成功！");
    }
  }
}
</script>

<style scoped>
.pagination {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

::deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #12C294;
}

::deep .el-dialog__title {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
}
</style>
