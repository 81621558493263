<template>
  <div class="warp">
     <input type="file" @change="uploadCover" ref="uploadFile" accept="image/*" hidden/>
    <!-- <el-dialog title="文件上传" :visible.sync="dialogUpload" :append-to-body="true" :destroy-on-close="true">
      <div class="mater-upload-container">
        <Simple ref="upload" :headers="headers" :before-upload="beforeUpload" :accept="accepts"
          :upload-arguments="uploadArgumentsObj" :limit="limit" :on-exceed="fileLimitFn" :base-url="baseUrl"
          :chunk-size="chunkSize" @success="success">
        </Simple>
      </div>
    </el-dialog> -->
    <el-form label-width="85px" ref="rescource" :model="form" :rules="rules">
      <div class="w-row flex_row row1">
        <div class="w-r-l">
          <div>
            <el-form-item label="存放位置:">
              <div style="display: flex;">
              <div style="margin-top: 7px;">
                <el-tree :show-checkbox="true"  :default-checked-keys="[form.contentsId]" :check-strictly="true" :data="data" ref="tree" :props="defaultProps"
                  :highlight-current="true" node-key="id" @check="handleCheckChange" @check-change="handleClick" ></el-tree>
              </div>
             <div style="padding: 5px;margin-left: 10px;margin-top: -6px;">
               <el-button size="small" @click="dialogLocal=true"><i class="el-icon-edit"></i>位置编辑</el-button>
             </div>
              </div>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="资源选择:" prop="resources">
            <div class="mater-upload-container" style="margin-top: -10px;">
              <Simple ref="upload" :headers="headers" :before-upload="beforeUpload" :accept="accepts"
                :upload-arguments="uploadArgumentsObj" :limit="limit" :on-exceed="fileLimitFn" :base-url="baseUrl"
                :chunk-size="chunkSize" @success="success" style="width: 680px">
              </Simple>
            </div>
              <!-- <el-button @click="upLoad">{{form.resourcesUrl!=''?'已上传':'上传'}}</el-button> -->
            </el-form-item>
          </div>
          <div style="display: flex;">
            <el-form-item label="封面图片:" prop="coverUrl">
            <div>
              <img :src="form.coverUrl" :class="form.coverUrl?'cover1':'cover'" style="object-fit:cover" @click="$refs.uploadFile.click()"/>
            </div>
            </el-form-item>

          </div>
          <div style="display: flex;" v-for="(item,index) in form.resources" :key="index">
            <div>
            <el-form-item :label="'文件名'">
              <el-input v-model="form.resources[index].name" style="width: 270px;"></el-input>
            </el-form-item>
            </div>
            <!-- <div>
              <el-form-item label="上传地址:">
                <el-input style="width: 320px;" :value="item.url" readonly></el-input>&nbsp;&nbsp;<i class="el-icon-remove" style="cursor: pointer;" @click="delResource(index)"></i>
              </el-form-item>
            </div> -->
          </div>

        </div>
        <!--  资源存放位置dialog -->
        <!--  资源存放位置dialog -->
        <el-dialog title="存放位置管理" :visible.sync="dialogLocal" :append-to-body="true" :destroy-on-close="true" width="400px">
          <resourceLocation></resourceLocation>
        </el-dialog>
      </div>
      <div class="w-row">
        <el-form-item label="资源简介:" prop="introduce">
          <el-input type="textarea" v-model="form.introduce" :rows="5" placeholder="请输入资源简介"></el-input>
        </el-form-item>
      </div>
      <div class="w-row">
        <el-form-item label="资源标签:">
          <el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="handleClose(tag)">
            {{tag}}
          </el-tag>
          <div v-if="inputVisible" style="display: inline-block;">
             <div class="searchBox" v-show="labels.length>0">
               <div class="item" v-for="item in labels" :key="item.id" @click="selectLabel(item.name)">{{item.name}}</div>
             </div>
            <el-input class="input-new-tag"  v-model="inputValue" ref="saveTagInput" size="small"
               @input="searchInput" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
            </el-input>
          </div>
          <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 增加标签</el-button>
        </el-form-item>
      </div>
      <div class="btn" style="text-align: center;">
        <el-button @click="$emit('closeDialog')" style="margin-right: 60px;border: 1px #12C294 solid;color: #12C294;width: 100px;background-color: #fff;">取 消</el-button>
        <el-button @click="saveResource" style="background-color: #12C294;color: #fff;width: 100px;">提 交</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
  import resourceLocation from "@/views/resourceCenter/components/center/resource-location";
  import {
    getContentsTree_api,
    savePersonalDiskContents_api,
    // resourceSave_api,
    deleteContents_api,
    updatePersonalDiskContents_api,
    resourceSaveBatch_api,
    getLabelList_api,
    putFile_api
  } from "@/api/resourceCenter/school/center"
  import url from "@/api/api";
  import Simple from '@/components/simple-upload/simple'
  import {
    sessionGet
  } from "utils/local";
  export default {
    name: 'MaterUpload',
    components: {
      Simple,
      resourceLocation
    },
    data: () => ({
      dialogLocal:false,
      form: {
        labels: '',
        introduce: '',
        contentsId: '',
        resources: '',
        size: '',
        id:'',
        coverUrl:''
      },
      rules: {
        resources: {
          required: true,
          message: '请上传资源',
          trigger: 'blur'
        },
        introduce: {
          required: false,
          message: '请填写资源简介',
          trigger: 'blur'
        }
      },
      dialogUpload: false,
      data: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
      accepts: '*',
      acceptsObj: {
        video: ['video/mp4'],
        image: [
          'image/png',
          'image/gif',
          'image/jpeg',
          'image/jpg',
          'image/bmp',
          '.'
        ], // 火狐的accept只支持【.png,.jpg】这种形式，为了兼容，使用 .
        audio: ['audio/mp3', 'audio/mpeg'],
        ppt: [
          'application/vnd.ms-powerpoint',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          '.ppt',
          '.pptx'
        ],
        excel: [
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ],
        zip: ['application/zip', 'application/x-zip-compressed']
      },
      acceptDesc: {
        video: 'mp4',
        image: 'png,gif,jpeg,jpg,bmp',
        audio: 'mp3',
        ppt: 'ppt',
        excel: 'xls,xlsx',
        zip: 'zip'
      },
      // 临时自测使用
      uploadArguments: {
        type: 'zip'
      },
      limit: 1,
      chunkSize: 10 * 1024 * 1024,
      share: 1, // 是否共享 0私有  1共享
      currDirectoryId: 1, //当前选择目录id
      currDirectory: '', //当前选择目录对象
      directoryName: '', //添加的目录名
      saveNodeTmp: [],
      labels:[],
      selectedLabel:''//选中的标签
    }),
    computed: {
      headers() {
        return {
          Authorization: 'token'
        };
      },
      baseUrl() {
        return url.baseUrl;
      },
      uploadType() {
        return this.uploadArguments.type;
      },
      uploadArgumentsObj() {
        return {
          ...this.uploadArguments,
          share: this.share
        };
      },
      userInfo() {
        let user = sessionGet('userInfo')
        if (user) {
          return user
        } else {
          return {}
        }
      }
    },

    created() {
      //获取树
      this.init();
    },
    methods: {
      uploadCover(e){
        let file=e.target.files[0];
        var formData = new FormData();
        formData.append('file', file);
        if(file.size<1024*1024*5){
          putFile_api(formData).then(res=>{
          if(res.code==200){
            this.form.coverUrl=res.data.link;
          }
        })
        }else{
           this.$message.warning("请上传小于5M的图片");
        }

      },
      handleClose(tag) {
        this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      },

      showInput() {
        this.inputVisible = true;
        this.$nextTick(() => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      },

      handleInputConfirm() {
       setTimeout(()=>{
         let inputValue =this.selectedLabel?this.selectedLabel:this.inputValue;
         if (inputValue) {
           if(this.dynamicTags.find(item=>item==inputValue)){
             this.$message.warning("标签"+inputValue+'已存在');
           }else{
             this.dynamicTags.push(inputValue);
           }
         }
         this.inputVisible = false;
         this.inputValue = '';
         this.labels='';
         this.selectedLabel='';
       },200)
      },
      //模糊查询输入的关键词
      searchInput(input){
        if(input.trim().length>0){
          this.getLabelList(input);
        }else{
          this.labels=''
        }
      },
      //选择查询的标签
      selectLabel(label){
        this.selectedLabel=label;
      },
      beforeUpload(file) {
        this.$refs.upload.clearFiles();//清空文件
        console.log('beforeAvatarUpload -> file', file);
        // if (this.acceptsObj[this.uploadType].indexOf(file.type) === -1) {
        //   this.$notify({
        //     message: '只能上传' + this.acceptDesc[this.uploadType],
        //     type: 'warning',
        //     offset: 50
        //   });
        //   return false;
        // }
        if (!file.size) {
          setTimeout(() => {
            this.$notify({
              message: '不能上传大小为0的文件',
              type: 'warning',
              offset: 50
            });
          }, 500);
          return false;
        }
        this.form.size = file.size / 1024;
        return this.propertyRestrictions(file);
      },
      // 文件个数限制钩子
      fileLimitFn() {
        this.$message.warning(
          `最多支持选择${this.limit}个文件`
        );
      },
      // 清空文件，暂未使用
      clearFiles() {
        this.$refs.upload.clearFiles();
      },
      success(r) {
        this.$message.success('上传成功');
        //this.dialogUpload = false;
        this.form.resources = [r.pop()];
        // this.form.types = r.types;
        // this.form.name = r.fileName;
      },
      // 属性限制
      propertyRestrictions(file) {
        return new Promise((resolve, reject) => {
          if (this.uploadType === 'image') {
            this.verifyResolution(file).then((isVerifyResolution) => {
              if (!isVerifyResolution) {
                this.$message('不支持上传4K视频');
                reject();
              }
            })
            //const isVerifyResolution = await this.verifyResolution(file);
            // if (!isVerifyResolution) {
            //   this.$message('不支持上传4K视频');
            //   reject();
            // }
          }
          resolve(true);
        });
      },
      // 分辨率校验
      verifyResolution(file, maxWidth = 3840, maxHeight = 2160) {
        console.log(maxWidth)
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function() {
            if (reader.readyState === 2) {
              const img = new Image();
              img.src = reader.result;
              img.onload = function() {
                const width = this.width;
                const height = this.height;
                const bool = width > maxWidth || height > maxHeight;
                if (bool) {
                  resolve(false);
                }
                resolve(true);
              };
            }
          };
        });
      },
      //上传按钮
      // upLoad() {
      //   this.dialogUpload = true;
      // },
      init() {
        this.getContentsTree().then((data) => {
          //data[0].value = data[0].id;
          this.currDirectory = data[0];

        })
      },
      //获取网盘列表
      getContentsTree() {
        return new Promise((resolve) => {
          getContentsTree_api().then(r => {
            this.data = r.data;
            resolve(r.data);
          });
        })

        // if(data.length>0){
        //   //this.handleDirectory()
        //   //this.currDirectory=data[0];
        //   //this.form.contentsId=data[0].id;
        //   //this.findAllNode(data[0]);
        // }


      },
      //增加资源树
      async savePersonalDiskContents() {
        //  let parentsIds=this.saveNodeTmp.slice(0,this.saveNodeTmp.indexOf(this.currDirectory.id));
        //console.log(this.saveNodeTmp.indexOf(this.currDirectory.id))
        let parentId = this.currDirectory.id?this.currDirectory.id:this.currDirectory.value,
          parentsId = '';
          console.log(this.currDirectory)
        if (this.currDirectory.level > 1) {

          parentsId = this.currDirectory.path.slice(0, this.currDirectory.path.indexOf(this.currDirectory.value))
            .toString();
        } else {
          var id=this.currDirectory.id?this.currDirectory.id:this.currDirectory.value;
          parentsId = id.toString();
        }

        console.log(parentsId)
        //获取增加资源的目录层级
        if (this.directoryName.trim() != "") {
          let datas = {
            "name": this.directoryName,
            "parentId": parentId,
            "parentsId": parentsId,
            "userId": this.userInfo.user_id,
            "sort": this.currDirectory.level + 1
          };
          const {
            success
          } = await savePersonalDiskContents_api(datas);
          if (success) {
            this.$message.success("添加成功");
            this.getContentsTree();
          }
          return false;
        }
        this.$message.warning("请输入目录名");
      },
      //修改目录
      async updatePersonalDiskContents() {
        let id = this.currDirectory.id?this.currDirectory.id:this.currDirectory.value;
        let treeId=this.$refs.tree.getCurrentKey();
        if (id != 1) {
          this.$prompt('请输入目录名', '编辑目录', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            closeOnClickModal: false,
            inputValue: this.currDirectory.label,
            inputPattern: /^[\s\S]*.*[^\s][\s\S]*$/,
            inputErrorMessage: '请输入目录名'
          }).then(async ({
            value
          }) => {
            let datas = {
              "name": value,
              "id": id,
              "userId": this.userInfo.user_id
            };
            const {
              success
            } = await updatePersonalDiskContents_api(datas);
            if (success) {
              this.$message.success("修改成功");
              this.currDirectory.label = value;
              this.getContentsTree();
              this.$emit("updateList",{"contentId":treeId?treeId:1});
            }
          });


          return false;
        }
        this.$message.warning("不能修改根目录");

      },
      //删除目录
      deleteContents() {
        let id=this.currDirectory.id?this.currDirectory.id:this.currDirectory.value;
        if (id != 1) {
          this.$confirm('是否删除'+this.currDirectory.label+'目录?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            const {
              success
            } = await deleteContents_api({
              id: id
            });
            if (success) {
              this.$message.success("删除成功！");
              this.getContentsTree();
              this.currDirectory = {
                value: 1,
                level: 1
              };
              this.currDirectoryId = 1;
            }

          })

          return false;
        }
        this.$message.warning("不能删除根目录");
      },
      //获取选中的节点
      handleDirectory() {
        this.currDirectory = this.$refs.cascader.getCheckedNodes()[0];
      },
      // 递归全部节点
      // findAllNode(data) {
      //   if (!this.saveNodeTmp.includes(data.id)) {
      //     this.saveNodeTmp.push(data.id)
      //   }
      //   if (data.children.length>0) {
      //     this.findAllNode(data.children[0])
      //   }
      // },

      //选择tree
      handleCheckChange(data) {
        this.form.contentsId = data.id
      },
      //删除文件
      delResource(index){
        this.form.resources.splice(index,1);
      },
      //保存资源
      saveResource() {
        this.form.labels = this.dynamicTags.toString();
        this.$refs['rescource'].validate(async (valid) => {
          if (valid) {
            let obj=[];
            this.form.resources.map(val=>{
              obj.push({
                "contentsId":this.form.contentsId,
                "introduce":this.form.introduce,
                "id":this.form.id,
                "labels":this.dynamicTags.toString(),
                "name":val.name,
                "resourcesUrl":val.url,
                "size":val.size?val.size:this.form.size,
                "types":val.type,
                "coverUrl":this.form.coverUrl
              })
            })
            const {
              success
            } = await resourceSaveBatch_api(obj);
            if (success) {
              this.$message.success("修改成功");
              this.$emit("upDateList");
              this.$emit("closeDialog");
              this.$emit("getDiskMax");
            }
          }
        })
      },
      //获取资源标签
      async getLabelList(name){
       const{data}=await getLabelList_api({'name':name});
       this.labels=data;
      },
      //单选
      handleClick(data,checked){
        if(checked){
          this.$refs.tree.setCheckedNodes([data]);
        }
      },
      //设置资源
      setDatas(r){
        //位置
        this.form.contentsId=r.contentsId;
        //大小
        this.form.size=r.size;
        //资源
        this.form.resources=[{"name":r.name,"url":r.resourcesUrl}];
        //简介
        this.form.introduce=r.introduce;
        //标签
        this.dynamicTags=r.labelList.map(val=>{return val.name});
        //id
        this.form.id=r.id;
        //coverUrl
        this.form.coverUrl=r.coverUrl?r.coverUrl:'https://admin.wuhousmartedu.com:9000/prod/upload/20220802/f5b472c6a02fb981d72b2c24b05582e6.png';
      }
    }
  };
</script>

<style lang="scss" scoped>
  ::deep .el-progress-bar{
        width:73%;
        margin-right: 0px;
  }
  ::deep .el-dialog{
     border-radius: 10px;
   }
  ::deep .el-form-item__label{
     color: #bcbcbc;
   }
   ::deep .el-button--default{
    background-color:#ebfaf6;color: #2FC9A1;border-color: #ebfaf6;border-radius: 5px !important;
   }
   ::deep .el-tag{
     background-color:#ebfaf6;color: #2FC9A1;border-color: #ebfaf6;
   }
   ::deep .el-tag .el-tag__close{
     color: #2FC9A1;
   }
   ::deep.el-tag .el-tag__close:hover{
     background-color:#6ad2b745;
     color:#fff
   }
   .cover{
     width: 120px;
     height: 120px;
     cursor: pointer;
   }
   .cover1{
     width: 120px;
     height: 120px;
     border: 1px #bcbcbc dashed;
     border-radius: 4px;
     cursor: pointer;
   }
   .searchBox{
     position: absolute;
     bottom: 40px;
     border: 1px #12C294 solid;
     background-color: #fff;
     padding: 5px;
     margin-left: 25px;
     .item{
       line-height: 20px;
       cursor: pointer;
     }
   }
  .warp {}

  .warp .w-row {}

  .warp .w-row .w-r-l {

    width: 100%;
  }

  .warp .w-row .w-r-r {
    border: 1px #dcdfe6 solid;
    width: 65%;
    height: 273px;
  }

  .warp .w-row .w-r-r .title {
    text-align: center;
    height: 35px;
    line-height: 35px;
    background-color: #dfefff;
  }

  .warp .w-row .w-r-r .content {
    padding:8% 5%;
  }

  .el-tag+.el-tag {
    margin-left: 10px;
  }

  .button-new-tag {
    margin-left: 10px !important;
    height: 32px !important;
    line-height: 30px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .input-new-tag {
    width: 90px !important;
    margin-left: 10px !important;
    vertical-align: bottom !important;
  }

  /* 上传组件样式*/
  .mater-upload-container {
    >>>.simple-upload-container {
      border: none;
      max-height: 500px;
    }
  }
.simple-upload-container{
    border: none !important;
  }
  /*级联选择器自定义浮层类名，实现 前面单选框隐藏，点击文本即可选中 功能 */
  .popper-custom {
    .el-cascader-panel {
      .el-cascader-menu {
        .el-radio {
          height: 100%;
          width: 150px;
          opacity: 0;
          position: absolute;

          .el-radio__input {
            .el-radio__inner {
              border: none;
            }
          }

          .el-radio__input.is-checked {
            .el-radio__inner {
              background: none;
            }
          }
        }
      }
    }
  }
  #drop-area {
      width: 100%;
      height: 60px;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.45);
      text-align: center;
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      p{
        margin: 10px auto;
      }
    }
    .drop-active{
    background-color: rgba(231,234,246,0.8);
  }
</style>
